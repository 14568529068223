<!-- 
 * @description: 修改了海报
 * @date: 
 * @author: 程
 * @LastEditors: 郭晴
 * @LastEditTime: 2023-11-22 15:19:23 
 * @FilePath:  
 * @version: V1.0.0 
!-->
<template>
  <div>
    <div class="top">
      <!-- <img src="@/assets/introduce/i1.png" /> -->
      <div class="top-box">
        <div class="box-title">我们的公司</div>
        <div class="box-text">
          大连小美云服科技有限公司
          坐落于美丽的海滨城市大连，公司拥有一支优秀的软件开发团队，凭借着专业的服务和成熟的技术，在软件开发领域迅速崛起，并以一流的产品和完善的服务深受顾客青睐
          ，公司自主研发的saas云产品：【小美智能客服系统】以优异的稳定性和良好的用户体验，赢得了市场用户的支持和认可。公司将秉承着严谨的工作态度，不断致力于为客户提供优质满意的产品和服务，帮助客户解决需求痛点。
          <br /><br />
          公司坚持以科技求发展，以人才为动力，将不断的为用户提供满意的各种应用系统，不断促进行业发展，推进社会进步。目前公司销售的所有软件产品均由我公司软件工程师自主研发，拥有自主知识产权，
          并可在线为客户提供专业的产品售前与售后支持。
        </div>
      </div>
    </div>
    <div class="center">
      <div class="center-title">我们的文化</div>
      <div class="center-main">
        <div class="center-box">
          <img src="@/assets/introduce/i4.png" alt="经营理念" />
          <div class="center-box-title">经营理念</div>
          <p>以客户为中心</p>
          <p>连接服务</p>
          <p>合作共赢</p>
        </div>
        <div class="center-box">
          <img src="@/assets/introduce/i5.png" alt="价值观" />
          <div class="center-box-title">价值观</div>
          <p><span>务实</span><span>诚信</span></p>
          <p><span>创新</span><span>极致</span></p>
        </div>
        <div class="center-box">
          <img src="@/assets/introduce/i6.png" alt="团队精神" />
          <div class="center-box-title">团队精神</div>
          <p><span>担当</span><span>活力</span></p>
          <p><span>高效</span><span>协作</span></p>
        </div>
        <div class="center-box">
          <img src="@/assets/introduce/i7.png" alt="质量理念" />
          <div class="center-box-title">质量理念</div>
          <p>产品人性化</p>
          <p>标准全球化</p>
          <p>服务贴心化</p>
        </div>
        <div class="center-box">
          <img src="@/assets/introduce/i8.png" alt="产品理念" />
          <div class="center-box-title">产品理念</div>
          <p>你负责伟大的产品</p>
          <p>我们帮你打造伟大的服务</p>
        </div>
      </div>
    </div>
    <div class="end">
      <div class="end-end">
        <div class="end-main">
          <div class="end-box">
            <div class="end-box-title left">企业愿景</div>
            <div class="end-box-text">科技改变生活，共创美好未来</div>
          </div>
          <div class="end-box">
            <img src="@/assets/introduce/i3.png" alt="办公环境" />
          </div>
        </div>
        <div class="end-main">
          <div class="end-box">
            <img src="@/assets/introduce/i2.png" alt="大楼一角" />
          </div>
          <div class="end-box">
            <div class="end-box-title right">企业文化</div>
            <div class="end-box-text right">不唯经验，不唯教条，不唯模式</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "公司介绍 | 小美客服系统",
    meta: [
      {
        name: "keyWords",
        content:
          "在线客服,工单系统，多渠道客服软件系统，大模型智能客服系统，AI在线客服，大模型智能客服，客服软件，智能客服系统，智能客服软件，智能机器人，AI客服系统，工单系统，在线客服系统，在线客服软件，ai机器人，网站客服软件，网站客服系统，小美客服系统，小美客服软件，小美客服机器人，小美智能客服，小美客服平台，防劫持客服系统，抗攻击客服系统，防劫持客服软件，抗攻击客服软件",
      },
      {
        name: "description",
        content:
          "小美客服系统是通过多种渠道提供即时、高效和个性化的服务。我们系统利用人工智能、大数据分析和机器学习等技术，实现智能分流、自动回复和问题识别等功能，大大提升客户服务效率与满意度。同时，我们的客服系统还能收集客户反馈，帮助企业优化产品和服务，增强竞争力。",
      },
    ],
  },
};
</script>
<style scoped lang="less">
.top {
  height: 730px;
  width: 100%;
  position: relative;
  background-image: url("@/assets/introduce/banner_2.png");
  background-size: 107% 100%;
  @media screen and (max-width: 700px) {
    height: 650px;
  }
}

// .top img {
//   width: 100%;
//   height: 650px;
//   @media screen and (max-width: 700px) {
//     height: 150px;
//   }
// }

.top-box {
  width: 1100px;
  height: 332px;
  position: absolute;
  // 背景透明
  background: transparent;
  top: 180px;
  left: 50%;
  margin-left: -600px;
  padding: 50px;
  @media screen and (max-width: 700px) {
    width: 360px;
    margin-left: -215px;
    top: 90px;
    padding: 35px;
    height: 410px;
  }
}

.box-title {
  // color: #021b64;
  // font-size: 30px;
  // text-align: center;
  text-align: left;
  font-family: PingFangSC-Regular;
  font-size: 52px;
  color: #ffffff !important;
  z-index: 999999;
  font-weight: 400;
  margin-bottom: 40px;
  @media screen and (max-width: 700px) {
    font-size: 25px;
  }
}

.box-text {
  // color: #021b64;
  opacity: 1;
  // font-size: 18px;
  font-family: PingFangSC-Regular;
  font-size: 19px;
  color: #ffffff !important;
  font-weight: 400;
  @media screen and (max-width: 700px) {
    font-size: 15px;
  }
}

.center {
  background-color: #304fae;
  width: 100%;
  height: 379px;
  padding: 50px 0;
  margin-bottom: 200px;
  @media screen and (max-width: 700px) {
    height: 290px;
    margin-bottom: 130px;
  }
}

.center-title {
  font-size: 34px;
  color: #fff;
  text-align: center;
  margin-top: 15px;
  @media screen and (max-width: 700px) {
    font-size: 25px;
  }
}

.center-main {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  @media screen and (max-width: 700px) {
  }
}

.center-box {
  width: 200px;
  text-align: center;
  @media screen and (max-width: 700px) {
  }
}

.center-box img {
  width: 60px;
  height: 60px;
  @media screen and (max-width: 700px) {
    width: 40px;
    height: 40px;
  }
}

.center-box-title {
  color: #fff;
  font-size: 22px;
  margin-bottom: 30px;
  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
}

.center-box p {
  color: #fff;
  opacity: 0.7;
  font-size: 18px;
  margin: 5px 0;
  @media screen and (max-width: 700px) {
    font-size: 10px;
  }
}

.center-box p span {
  margin: 0 5px;
  @media screen and (max-width: 700px) {
  }
}

.end {
  height: 860px;
  position: relative;
  padding-bottom: 120px;
  @media screen and (max-width: 700px) {
    padding-bottom: 60px;
    height: 500px;
  }
}

.end-end {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -600px;
  @media screen and (max-width: 700px) {
    margin-left: -200px;
    left: 50%;
  }
}

.end-main {
  display: flex;
  @media screen and (max-width: 700px) {
  }
}

.end-box {
  width: 600px;
  height: 430px;
  @media screen and (max-width: 700px) {
    width: 200px;
    height: 250px;
  }
}

.end-box img {
  width: 600px;
  height: 430px;
  @media screen and (max-width: 700px) {
    width: 180px;
    height: 135px;
  }
}

.end-box-title {
  font-size: 38px;
  color: #021b64;
  padding-bottom: 10px;
  margin-top: 150px;
  border-bottom: 1px solid rgba(2, 15, 59, 0.3);
  @media screen and (max-width: 700px) {
    font-size: 20px;
    margin-top: 70px;
  }
}

.left {
  margin-right: 50px;
  @media screen and (max-width: 700px) {
    margin-right: 20px;
  }
}

.right {
  margin-left: 50px;
  @media screen and (max-width: 700px) {
    margin-left: 0px;
  }
}

.end-box-text {
  font-size: 26px;
  color: #021b64;
  opacity: 0.6;
  margin-top: 20px;
  @media screen and (max-width: 700px) {
    font-size: 14px;
  }
}
</style>
