<template>
  <div class="content">
    <!-- 版权声明 -->
    <el-card shadow="never" class="box-card">
      <div class="app">
        <el-row :gutter="20">
          <el-col :span="4"></el-col>
          <el-col :span="16">
            <div>
              <div class="header">
                <div class="header-div">
                  <div class="header-title">产品</div>
                  <div @click="router.push('/customer')" class="header-text">
                    在线客服
                  </div>
                  <div @click="router.push('/workorder')" class="header-text">
                    工单系统
                  </div>
                  <div @click="router.push('/robot')" class="header-text">
                    智能机器人
                  </div>
                </div>
                <div class="header-div">
                  <div class="header-title app">服务与支持</div>
                  <!-- <div @click="router.push('/question')" class="header-text">常见问题</div> -->
                  <div class="header-text">常见问题</div>
                  <div @click="router.push('/statement')" class="header-text">
                    隐私声明
                  </div>
                  <!-- <div class="header-text">隐私声明</div> -->
                  <div @click="router.push('/agreement')" class="header-text">
                    用户协议
                  </div>
                  <div @click="router.push('/developer')" class="header-text">
                    开发者中心
                  </div>
                  <!-- <div class="header-text">开发者中心</div> -->
                </div>
                <div class="header-div">
                  <div class="header-title">关于我们</div>
                  <div @click="router.push('/introduce')" class="header-text">
                    公司介绍
                  </div>
                  <div @click="router.push('/honor')" class="header-text">
                    资质荣誉
                  </div>
                  <!-- <div class="header-text">资质荣誉</div> -->
                  <div @click="router.push('/contact')" class="header-text">
                    联系我们
                  </div>
                  <div @click="router.push('/join')" class="header-text">
                    加入我们
                  </div>
                  <!-- <div class="header-text">加入我们</div> -->
                  <!-- <div @click="router.push('/quotation')" class="header-text">行情资讯</div> -->
                  <div class="header-text">行情资讯</div>
                </div>
                <div class="header-div">
                  <div class="header-title app">下载</div>
                  <div @click="router.push('/center')" class="header-text">
                    下载中心
                  </div>
                  <div @click="router.push('/journal')" class="header-text">
                    更新日志
                  </div>
                  <!-- <div class="header-text">更新日志</div> -->
                </div>
                <div class="header-div QR">
                  <div class="header-img-text">小美科技</div>
                  <img
                    class="QR-img"
                    src="~@/assets/up/wecatCode.png"
                    alt="企业微信"
                  />
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="4"></el-col>
        </el-row>
      </div>
      <div class="pc">
        <el-row :gutter="20">
          <el-col :span="2"></el-col>
          <el-col :span="20">
            <div>
              <div class="header">
                <div class="header-div">
                  <div class="header-title">产品</div>
                  <div @click="router.push('/customer')" class="header-text">
                    在线客服
                  </div>
                  <div @click="router.push('/workorder')" class="header-text">
                    工单系统
                  </div>
                  <div @click="router.push('/robot')" class="header-text">
                    智能机器人
                  </div>
                </div>
                <div class="header-div">
                  <div class="header-title app">服务与支持</div>
                  <!-- <div @click="router.push('/question')" class="header-text app">常见问题</div> -->
                  <div class="header-text app">常见问题</div>
                  <!-- <div @click="router.push('/statement')" class="header-text app">隐私声明</div> -->
                  <div class="header-text app">隐私声明</div>
                  <div
                    @click="router.push('/agreement')"
                    class="header-text app"
                  >
                    用户协议
                  </div>
                  <!-- <div @click="router.push('/developer')" class="header-text app">开发者中心</div> -->
                  <div class="header-text app">开发者中心</div>
                </div>
                <div class="header-div">
                  <div class="header-title">关于我们</div>
                  <div @click="router.push('/introduce')" class="header-text">
                    公司介绍
                  </div>
                  <!-- <div @click="router.push('/honor')" class="header-text app">资质荣誉</div> -->
                  <div class="header-text">资质荣誉</div>
                  <div @click="router.push('/contact')" class="header-text">
                    联系我们
                  </div>
                  <!-- <div @click="router.push('/join')" class="header-text">加入我们</div> -->
                  <div class="header-text">加入我们</div>
                  <!-- <div @click="router.push('/quotation')" class="header-text app">行情资讯</div> -->
                  <div class="header-text">行情资讯</div>
                </div>
                <div class="header-div">
                  <div class="header-title app">下载</div>
                </div>
                <div class="header-div">
                  <div class="header-img-text">小美科技</div>
                  <img
                    class="QR-img"
                    src="~@/assets/up/wecatCode.png"
                    alt="企业微信"
                  />
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="2"></el-col>
        </el-row>
      </div>
      <el-divider></el-divider>
      <div>
        <div class="end">
          <div class="end-div">
            <span class="header-end-text"
              >Copyright 大连小美云服科技有限公司</span
            >
          </div>
          <div class="end-div">
            <span class="header-end-text">辽ICP备2024037184号-1</span>
          </div>
          <div class="end-div">
            <img
              class="header-end-img"
              src="~@/assets/flooter/flooterLogo.png"
              alt="小美科技"
            />
            <span class="header-end-text">辽公网安备 21021102000889</span>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
</script>

<style scoped lang="less">
.content {
  width: 100%;
}
.pc {
  display: none;

  @media screen and (max-width: 700px) {
    display: block;
  }
}

:deep(.el-card__body) {
  background: #020f3b;
  padding: 60px;

  @media screen and (max-width: 700px) {
    padding: 40px;
  }
}

:deep(.el-card) {
  border: none;
  border-radius: 0;
}

:deep(.el-divider--horizontal) {
  color: #fff;
  opacity: 50%;
  width: 65%;
  margin: 30px auto;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-around;
}

.app {
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.header-div {
  @media screen and (max-width: 700px) {
    padding: 5px;
  }
}

.end {
  width: 880px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
    width: 100%;
  }
}

.header-title {
  color: #78d3ff;
  font-size: 24px;
  text-align: center;
  font-family: PingFangSC-Medium, sans-serif !important;

  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
}

.header-text {
  text-align: center;
  margin-top: 20px;
  color: #fff;
  font-size: 20px;
  opacity: 80%;
  cursor: pointer;

  @media screen and (max-width: 700px) {
    font-size: 16px;
  }
}

.header-img-text {
  font-size: 24px;
  color: #fff;

  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
}

.end-div {
  @media screen and (max-width: 700px) {
    margin-bottom: 10px;
  }
}

.header-end-text {
  color: #fff;
  font-size: 18px;
  opacity: 80%;

  @media screen and (max-width: 700px) {
    font-size: 15px;
  }
}

.header-end-img {
  width: 23px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
  margin-top: -5px;
}
.QR {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.QR-img {
  width: 120px;
  height: 120px;
  margin-top: 20px;
  @media screen and (max-width: 700px) {
    width: 70px;
    height: 70px;
  }
}
</style>
